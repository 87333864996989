<template>
    <div class="repairDetails">
        <rxNavBar  title="维修派单" ></rxNavBar>
        <div class="content">
            <div class="part">
                <span class="part-subtitle">报修详情</span>
                <div class="otherExpenses-inputpart">
                    <div class="otherExpenses-inputpart-row">
                        <div class="otherExpenses-address">{{repairDetail.roomDetailedAddress}}</div>
                    </div>
                    <div class="otherExpenses-inputpart-column">
                        <div class="otherExpenses-inputpart-row-bottom">
                            <div class="otherExpenses-inputpart-row-left">
                                <span class="part-inputpart-row-header">报修人：</span>
                                <span>{{repairDetail.applicationGlobaluserName ? repairDetail.applicationGlobaluserName : '暂无'}}</span>
                            </div>
                            <div class="otherExpenses-inputpart-row-right">
                                <span class="part-inputpart-row-header">联系方式：</span>
                                <span>{{repairDetail.applicationGlobaluserMoblile ? repairDetail.applicationGlobaluserMoblile : '暂无'}}</span>
                            </div>
                        </div>
                        <div class="otherExpenses-inputpart-row-bottom">
                            <div class="otherExpenses-inputpart-row-left">
                                <span class="part-inputpart-row-header">订单来源：</span>
                                <span>{{repairDetail.fromType ? repairDetail.fromType : '暂无'}}</span>
                            </div>
                            <div class="otherExpenses-inputpart-row-right">
                                <span class="part-inputpart-row-header">维修程度：</span>
                                <span class="part-inputpart-row-redtext">
                                    {{repairDetail.repairStatus ? repairDetail.repairStatus : '暂无'}}</span>
                            </div>
                        </div>
                        <div class="otherExpenses-inputpart-row-bottom">
                            <span class="part-inputpart-row-header">维修类型：</span>
                            <span>{{repairDetail.dictionaryTitle ? repairDetail.dictionaryTitle : '暂无'}}</span>
                        </div>
                        <div class="otherExpenses-inputpart-row-bottom">
                            <span class="part-inputpart-row-header">预约时间：</span>
                            <span class="part-inputpart-row-redtext">
                                {{repairDetail.appointmentTime ? repairDetail.appointmentTime : '暂无' }}</span>
                        </div>
                        <div class="repaireRemarksDiv">
                            <span class="part-inputpart-row-header">维修描述：</span>
                            <span class="part-inputpart-row-wrap">{{repairDetail.afterRentDesprition ? repairDetail.afterRentDesprition : '暂无'}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <!--      申请详情的图片  -->
            <div class="applyDetailImgs" v-if="applyImgList.length>0">
                <van-swipe :loop="false" :width="115" :show-indicators="false">
                    <van-swipe-item   v-for=" (item,i) in applyImgList" :key="item.id">
                        <div class="applyDetailImg">
                            <img  :src="item.path" @click="onChangeApply(i)"/>
                        </div>
                    </van-swipe-item>
                    <div class="custom-indicator">
                        {{applyImgList.length}} 图
                    </div>
                </van-swipe>
            </div>
            <van-image-preview v-model="showApply" :images="applyDetailImgList"  :start-position="start"></van-image-preview>

            <div class="part">
                    <div class="part-subtitle2" style="color:#ff5d3b;font-size: 14px;" v-if="isShow">* 当前房源三个月内存在同维修类型的工单 </div>
            </div>
            <div class="part">
                <div class="part-inputpart-finishFee">
                    <div class="part-inputpart-finishFee-row">
                        <span :class=" !caValidate.repairPersonnel ? 'content-none' : 'content-have' "></span>
                        <span class="part-inputpart-row-header" >维修人员</span>
                        <span class="content-divide" >|</span>
                        <div class="downMenu" :value="caValidate.staffName" @click="chooseRepairPersonnel">
                            <div :class=" !caValidate.repairPersonnel ? 'downMenuValue' : 'downMenuChoose' ">{{caValidate.staffName ? caValidate.staffName : '请选择'}}</div>
                        </div>
                        <img src="../../../assets/images/triangle.png" :class="!repairPerson?'downImage':'upImage'">
                    </div>
                    <!-- 维修人员弹窗 -->
                    <van-popup v-model="repairPerson" position="bottom">
                      <div class="searchDiv">
                            <van-search
                                placeholder="请输入"
                                v-model="repairPersonName"
                                size="large"
                                @input="getQueryrepairPerson()"
                            />
                        </div>
                        <van-picker
                            show-toolbar
                            value-key="userName"
                            :columns="repairPersonList"
                            @cancel="repairPerson = false"
                            @confirm="changeStaffName"
                        />
                    </van-popup>

                    <div class="part-inputpart-finishFee-row">
                        <span :class=" !caValidate.repairType ? 'content-none' : 'content-have' "></span>
                        <span class="part-inputpart-row-header" >维修类型</span>
                        <span class="content-divide" >|</span>
                        <div class="downMenu" :value="caValidate.repairTypeName" @click="chooseRepairType">
                            <div :class=" !caValidate.repairType ? 'downMenuValue' : 'downMenuChoose' ">{{caValidate.repairTypeName ? caValidate.repairTypeName : '请选择'}}</div>
                        </div>
                        <img src="../../../assets/images/triangle.png" :class="!repairTypeShow?'downImage':'upImage'">
                    </div>
                    <!-- 维修类型弹窗 -->
                    <van-popup v-model="repairTypeShow" position="bottom">
                         <div class="searchDiv">
                            <van-search
                                placeholder="请输入"
                                v-model="repairName"
                                size="large"
                                @input="getQueryrepairType()"
                            />
                        </div>
                        <van-picker
                            show-toolbar
                            value-key="dictionaryTitle"
                            :columns="repairTypeList"
                            @cancel="repairTypeShow = false"
                            @confirm="changeRepairType"
                        />
                    </van-popup>

                   <div class="part-inputpart-finishFee-row">
                        <span :class=" !caValidate.roomid ? 'content-none' : 'content-have' "></span>
                        <span class="part-inputpart-row-header" >房间</span>
                        <span class="content-divide" >|</span>
                        <div class="downMenu" :value="caValidate.roomName" @click="chooseRoom">
                            <div :class=" !caValidate.roomid ? 'downMenuValue' : 'downMenuChoose' ">{{caValidate.roomName ? caValidate.roomName : '请选择'}}</div>
                        </div>
                        <img src="../../../assets/images/triangle.png" :class="!roomShow?'downImage':'upImage'">
                    </div>
                    <!-- 房间弹窗 -->
                    <van-popup v-model="roomShow" position="bottom">
                        <van-picker
                            show-toolbar
                            value-key="roomName"
                            :columns="roomList"
                            @cancel="roomShow = false"
                            @confirm="changeRoom"
                        />
                    </van-popup>

                    <div id="finishTimePanel" class="part-inputpart-finishFee-row" @click="clickFinishTimeShow">
                      <span :class=" 0==caValidate.toTime.trim().length ? 'content-none' : 'content-have' "></span>
                      <span class="part-inputpart-row-header">预计上门时间</span>
                      <span class="content-divide">|</span>
                      <span class="width">{{caValidate.toTime}}</span>
                      <img class="part-inputpart-row-right part-inputpart-row-right-timepick" src="../../../assets/images/timepick.png">
                   </div>
                   <!-- 预计上门时间弹窗 -->
                   <van-popup v-model="isFinishTimeShow" position="bottom" class="selectTimePopup">
                        <van-datetime-picker v-model="currentDate" type="datetime" title="选择年月日" item-height="35px" @confirm="finishTimeConfirm" @cancel="finishTimeCancel"/>
                   </van-popup>

                    <div class="part-inputpart-finishFee-row">
                        <span :class=" !caValidate.estimatedCost ? 'content-none' : 'content-have' "></span>
                        <span class="part-inputpart-row-header">预计费用</span>
                        <span class="content-divide">|</span>
                        <input type="number" v-model="caValidate.estimatedCost">
                        <span class="part-inputpart-row-right" style="color: #d8d8d8">元</span>
                    </div>

                    <div class="part-inputpart-finishFee-row">
                        <span :class=" !caValidate.costBear ? 'content-none' : 'content-have' "></span>
                        <span class="part-inputpart-row-header" >费用承担方</span>
                        <span class="content-divide" >|</span>
                        <div class="downMenu" :value="caValidate.costBearName" @click="chooseCostBear">
                            <div :class=" !caValidate.costBear ? 'downMenuValue' : 'downMenuChoose' ">{{caValidate.costBearName ? caValidate.costBearName : '请选择'}}</div>
                        </div>
                        <img src="../../../assets/images/triangle.png" :class="!costBearShow?'downImage':'upImage'">
                    </div>
                    <!-- 费用承担方弹窗 -->
                    <van-popup v-model="costBearShow" position="bottom">
                        <van-picker
                            show-toolbar
                            value-key="dictionaryTitle"
                            :columns="costBearList"
                            @cancel="costBearShow = false"
                            @confirm="changecostBear"
                        />
                    </van-popup>

                    <!-- 费用承担方(公司) -->
                    <div class="part-inputpart-finishFee-change" v-if="caValidate.costBear=='0'||caValidate.costBear=='3'">
                        <span class="content-noRule"></span>
                        <span class="part-inputpart-row-header">公司承担</span>
                        <span class="content-divide">|</span>
                        <input type="number" v-model="caValidate.dataList[2].companyList[0].amount">
                        <span class="part-inputpart-row-right" style="color: #d8d8d8">元</span>
                    </div>

                    <!-- 费用承担方(业主) -->
                    <div class="part-inputpart-finishFee-change" v-if="caValidate.costBear=='1'||caValidate.costBear=='3'">
                        <span class="content-noRule"></span>
                        <span class="part-inputpart-row-header">业主承担</span>
                        <span class="content-divide">|</span>
                        <input type="number" v-model="caValidate.dataList[3].ownerList[0].amount">
                        <span class="part-inputpart-row-right" style="color: #d8d8d8">元</span>
                    </div>
                    <div class="part-inputpart-finishFee-row" v-if="caValidate.costBear=='1'||caValidate.costBear=='3'">
                        <span class="content-noRule"></span>
                        <span class="part-inputpart-row-header">业主剩余金额</span>
                        <span class="content-divide" style="margin-right: 0;">|</span>
                        <input style="margin-left: 0;" type="number" v-model="ownerRemaining.ownerRemainingAmount" disabled>
                        <span class="part-inputpart-row-right" style="color: #d8d8d8;">元</span>
                    </div>
                    <div class="part-inputpart-finishFee-row" v-if="caValidate.costBear=='1'||caValidate.costBear=='3'">
                        <span class="content-noRule"></span>
                        <span class="part-inputpart-row-header">最近应支日期</span>
                        <span class="content-divide" style="margin-right: 0;">|</span>
                        <input style="margin-left: 0;" v-model="ownerRemaining.predictBillPayTime" disabled>
                        <span class="part-inputpart-row-right" style="color: #d8d8d8;"></span>
                    </div>
                    <div class="part-inputpart-finishFee-row" v-if="caValidate.costBear=='1'||caValidate.costBear=='3'">
                        <span class="content-noRule"></span>
                        <span class="part-inputpart-row-header">状态</span>
                        <span class="content-divide" style="margin-right: 0;">|</span>
                        <input style="margin-left: 0;" v-model="ownerRemaining.billStatus" disabled>
                        <span class="part-inputpart-row-right" style="color: #d8d8d8;"></span>
                    </div>

                     <!-- 费用承担方(维修基金) -->
                    <div class="part-inputpart-finishFee-change" v-if="caValidate.costBear=='5'||caValidate.costBear=='3'">
                        <span class="content-noRule"></span>
                        <span class="part-inputpart-row-header">基金承担</span>
                        <span class="content-divide">|</span>
                        <input type="number" v-model="caValidate.dataList[4].maintenanceFundList[0].amount">
                        <span class="part-inputpart-row-right" style="color: #d8d8d8">元</span>
                    </div>

                    <!-- 费用承担方(租客) -->
                    <!-- <div class="part">
                        <span class="part-subtitle2">添加租客承担方</span>
                    </div>  -->
                    <div v-if="caValidate.costBear=='2'||caValidate.costBear=='3'">   
                      <div class="houseConfiguration" v-for="(item , index) in caValidate.dataList[0].renterList" :key="index">
                          <!-- 红色取消按钮 -->
                          <div class="cancelBtn" @click="cancelRenterBear(index)">
                              <div class="cancelImg"></div>
                          </div>
                          <!-- 配置详情 -->
                          <div class="houseDetail">
                              <!-- 租客 -->
                              <div class="configuration">
                                  <span class="content-noRule"></span>
                                  <span class="part-inputpart-row-header" >租客</span>
                                  <span class="content-divide" >|</span>
                                  <span class="configurationSelect" @click="chooseRenterBear(index)">
                                      {{item.userName}}
                                  </span>
                                  <img src="../../../assets/images/triangle.png" :class="!renterBearShow?'downImage':'upImage'">
                              </div>
                              <van-popup v-model="renterBearShow" position="bottom">
                                  <van-picker
                                      show-toolbar
                                      value-key="userName"
                                      :columns="renterList"
                                      @cancel="renterBearShow = false"
                                      @confirm="selectRenterBear"
                                  />
                            </van-popup>
                             
                              <!-- 分割线 -->
                              <div class="ruleOne"></div>
                              <!-- 租客承担 -->
                              <div class="configuration">
                                  <span class="content-noRule"></span>
                                  <span class="part-inputpart-row-header">租客承担</span>
                                  <span class="content-divide" style="margin-right: 0;">|</span>
                                  <input  style="width: 4rem;" type="number" v-model="item.amount"
                                      onkeyup="this.value= this.value.match(/\d+(\.\d{0,2})?/) ? this.value.match(/\d+(\.\d{0,2})?/)[0] : ''"
                                  >
                                  <span class="part-inputpart-row-right" style="color: #d8d8d8">元</span>
                              </div>
                              <div>

                              </div>
                          </div>
                          </div>

                          <div class="newAdd" @click="addRenterBear()">
                              <u >+ 新增租客</u>
                          </div>
                     </div>

                     <!-- 费用承担方(其他) -->
                    <div v-if="caValidate.costBear=='3'||caValidate.costBear=='4'">   
                      <div class="houseConfiguration" v-for="(item , index) in caValidate.dataList[1].supplierList" :key="index">
                          <!-- 红色取消按钮 -->
                          <div class="cancelBtn" @click="cancelSupplier(index)">
                              <div class="cancelImg"></div>
                          </div>
                          <!-- 配置详情 -->
                          <div class="houseDetail">
                              <!-- 供应商 -->
                              <div class="configuration">
                                  <span class="content-noRule"></span>
                                  <span class="part-inputpart-row-header" >供应商</span>
                                  <span class="content-divide" >|</span>
                                  <span class="configurationSelect" @click="chooseSupplier(index)">
                                      {{item.userName}}
                                  </span>
                                  <img src="../../../assets/images/triangle.png" :class="!supShow?'downImage':'upImage'">
                              </div>
                              <van-popup v-model="supShow" position="bottom">
                                  <van-picker
                                      show-toolbar
                                      value-key="userName"
                                      :columns="supplierList"
                                      @cancel="supShow = false"
                                      @confirm="selectSupplier"
                                  />
                              </van-popup>
                             
                              <!-- 分割线 -->
                              <div class="ruleOne"></div>
                              <!-- 供应商承担 -->
                              <div class="configuration">
                                  <span class="content-noRule"></span>
                                  <span class="part-inputpart-row-header">供应商承担</span>
                                  <span class="content-divide" style="margin-right: 0;">|</span>
                                  <input  style="width: 3.5rem;" type="number" v-model="item.amount"
                                      onkeyup="this.value= this.value.match(/\d+(\.\d{0,2})?/) ? this.value.match(/\d+(\.\d{0,2})?/)[0] : ''"
                                  >
                                  <span class="part-inputpart-row-right" style="color: #d8d8d8">元</span>
                              </div>
                              <div>

                              </div>
                          </div>
                          </div>

                          <div class="newAdd" @click="addSupplierBear()">
                              <u >+ 新增供应商</u>
                          </div>
                     </div>
               
                </div>
                <button class="part-button" :class="(!repaireRule() ? '' : 'part-button-enabled')"
                        :disabled="!repaireRule()"
                        @click="saveAfterRenBtn" >提交</button>
            </div>
        </div>

        <loadingFlag v-if="loadingFlag"></loadingFlag>

    </div>
</template>

<script>
import {
  NavBar,
  Image as VanImage,
  ImagePreview,
  DatetimePicker,
  Picker,
  Uploader,
  Popup,
  Swipe,
  SwipeItem,
  Loading,
  Overlay,
  Search,
  Button
} from 'vant'
    import loadingFlag from "./LoadingFlag";
    import rxNavBar from "../../../components/rongxun/rx-navBar/navBar";
    import diaMobileList from "../../../components/rongxun/rx-dialMobile/diaMobileList";

    import {getStaffId, globaluserId,dealImage, responseUtil} from "../../../libs/rongxunUtil";
    import {
      queryAfterRentRepair,
      queryBaseData,
      queryRepairPersonnelList,
      haveSameTypeDistribute,
      distributionChangeHouse,
      ownerRemainingAmount,
      ownerPayableDate,
      queryContractors,
      queryUserRoleList,
      saveRepairDistribute
    } from "../../../getData/getData";
    import Vue from "vue";
    Vue.use(Popup);
    Vue.use(Picker);
    export default {
        name: "RepairDetails",

        components: {
            [NavBar.name]: NavBar,
            [DatetimePicker.name]: DatetimePicker,
            [Uploader.name]: Uploader,
            [VanImage.name]:VanImage,
            [Popup.name]: Popup,
            [ImagePreview.Component.name]: ImagePreview.Component,
            rxNavBar,
            [Swipe .name]:Swipe,
            [SwipeItem .name]:SwipeItem,
            [Loading .name]:Loading,
            [Overlay .name]:Overlay,
            [Search.name]: Search,
          [Button.name]: Button,
            loadingFlag,
          diaMobileList,
        },

        data() {
            return {
              //表单提交数据
              caValidate: {
                repairPersonnel: '',//维修人员
                staffName: '',
                toTime: '',//预计上门时间
                costBear: '',//费用承担方
                costBearName: '',
                estimatedCost: '',//预计费用
                repairType: '',//维修类型
                repairTypeName: '',
                roomid: '',//房间
                roomName: '',
                dataList:[{renterList:[{globaluser_id:'',amount:''}]},{supplierList:[{globaluser_id:'',amount:''}]},{companyList:[{amount:''}]},{ownerList:[{amount:''}]}]},
                repairPersonList: [],
                repairTypeList: [],
                repairTypeListCopy: [],
                repairPersonListCopy: [],
                roomList: [],
                costBearList: [
                    {dictionaryValue: '0', dictionaryTitle: '公司'},
                    {dictionaryValue: '1', dictionaryTitle: '业主'},
                    {dictionaryValue: '2', dictionaryTitle: '租客'},
                    {dictionaryValue: '3', dictionaryTitle: '其他'},
                    {dictionaryValue: '4', dictionaryTitle: '施工负责人'},
                    {dictionaryValue: '5', dictionaryTitle: '维修基金'},
                ],
                repairPerson:false,
                repairTypeShow:false,
                isShow:false,
                roomShow:false,
                costBearShow: false,
                ownerRemaining:{
                  ownerRemainingAmount:0,
                  predictBillPayTime:'',
                  billStatus:'',
                },
                renterList:[],
                RenterBearIndex:0,
                renterBearShow: false,
                supplierList:[],
                supShow: false,
                supIndex:0,
                repairName: '',
                repairPersonName: '',

                //零件添加的div
                houseConfigurationList : [],
                roleType: '1',  //标识，用于判断入口是我的页面的租后工单（装修工人等 是 0   否 1）
                loadingFlag : false,
                disabled : false,
                //============================报修详情===============
                //初始化时用的租后ID
                id : '',
                //初始化返回的详情信息
                repairDetail : {},
                isFinishTimeShow: false,
                currentDate: new Date(),
                applyImgList : [],
                // 反馈图片列表
                imgList: [],
                showApply : false,
                applyDetailImgList : [],
                start : 0,
                //===========================维修详情===============
                //维修详情初始化数据
                finishDetail : {},
                // 完成费用
                lastFee: '',
                laborCost:'',
                // componentCost:'',
                componentCostTotal:'',
                finishFeeImgList:[],
                // 完成时间
                finishTime: '',
                // 处理反馈
                afterRentDesprition: '',
                userName:"",
                supplierShow:false,    //供应商列表弹出/隐藏
                roomHouse_id:undefined,
                setHouse_id:undefined,
                houseType:undefined,
            }
        },
        created() {
            this.ownerRemaining = this.$options.data().ownerRemaining
            this.id = this.$route.query.id
            this.repair_id = this.$route.query.repair_id
            this.roleType = this.$route.query.roleType  //是否是装修负责人  0 是    1 否
            this.roomHouse_id = this.$route.query.roomHouse_id
            this.setHouse_id = this.$route.query.setHouse_id
            this.houseType = this.$route.query.houseType
            this.queryAfterRentRepair()
            this.initDb()

            this.queryRepairPersonnelList();
            this.selectHouse();
            this.ownerRemainingAmount();
            this.ownerPayableDate();
            this.getQueryContractors();
            this.getQuerySupplierList();
        },
        methods: {
            //初始化字典
            initDb() {
                let that = this
                let initData = {}
                initData.dbName = ['repairType']
                initData.fdName = ['COSTBEARMAP']
                queryBaseData(initData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        that.repairTypeList = response.data.data.repairType;
                        that.repairTypeListCopy = response.data.data.repairType;
                        // that.costBearList = response.data.data.COSTBEARMAP;
                        // console.log("costBearList:",that.costBearList)
                    })
                })
            },
            onChangeApply(i) {
                this.start=i
                this.showApply=true
            },

            //报修详情初始化
            queryAfterRentRepair(){
                let that = this
                let initData = {}
                initData.id = that.id
                queryAfterRentRepair(initData).then(function (response) {
                    responseUtil.dealResponse(that,response,()=>{
                        that.repairDetail = response.data.data
                        that.applyImgList = response.data.data.imgList
                        for(let i=0;i<that.applyImgList.length;i++){
                            that.applyDetailImgList.push(that.applyImgList[i].path)
                        }
                    })
                })
            },
            repaireRule(){
              if(this.caValidate.repairPersonnel != '' && this.caValidate.repairType != '' && this.caValidate.roomid != '' &&
                  this.caValidate.toTime != '' && this.caValidate.estimatedCost != '' && this.caValidate.costBear != '' ){
                      return true;
                }

            },
            checkoutCost(){
                let count=0
                for(let i in this.caValidate.dataList[0].renterList){
                    count+=Number(this.caValidate.dataList[0].renterList[i].amount)
                }
                for(let i in this.caValidate.dataList[1].supplierList){
                    count+=Number(this.caValidate.dataList[1].supplierList[i].amount)
                }
                count+=Number(this.caValidate.dataList[2].companyList[0].amount)
                count+=Number(this.caValidate.dataList[3].ownerList[0].amount)
                count+=Number(this.caValidate.dataList[4].maintenanceFundList[0].amount)
                console.log(count)
                if(count==this.caValidate.estimatedCost){
                    return true
                }
                return false

            },
            //维修完成保存接口
            saveAfterRenBtn(){
              console.log("save:",this.caValidate)

              //校验承担费用是否等于预计费用
                if(!this.checkoutCost()){
                    responseUtil.alertMsg(this, '总承担费需等于预计费用')
                    return
                }

                this.disabled = true
                this.saveAfterRentRepair()
                setTimeout(()=>{
                    this.disabled = false
                },2000)

            },
            //维修派单保存接口
            saveAfterRentRepair (){
                let that = this
                that.loadingFlag = true

                let saveData = that.caValidate
                    saveData.user_id = globaluserId();  //修改人
                    saveData.reid = that.repair_id
                    saveData.dataList=that.caValidate.dataList
                    saveData.toTime = new Date(that.caValidate.toTime)
                    
                    console.log('saveData:',saveData)
                    
                    saveRepairDistribute(saveData).then(function (response) {
                      responseUtil.dealResponse(that, response, () => {
                        responseUtil.alertMsg(that,'派单完成')
                        that.skip()
                        that.loadingFlag = false

                      })
                    })

            },

            //维修完成跳转页面
            skip(){
                this.$router.go(-1)
            },

            queryRepairPersonnelList() {
                let that = this
                let initPersion = {}
                initPersion.user_id = globaluserId()
                initPersion.reid = that.repair_id
                queryRepairPersonnelList(initPersion).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        that.repairPersonList = response.data.data.repairPersonnelList
                        that.repairPersonListCopy = response.data.data.repairPersonnelList
                    })
                })
            },
            haveSameTypeDistribute(e){
            console.log("e")
            console.log(e)
            let that=this
            let initData={}
            initData.reid = that.repair_id
            initData.repairType_id = e
            haveSameTypeDistribute(initData).then(function (response) {
                responseUtil.dealResponse(that, response, () => {
                    console.log("response:",response)
                    if(response.data.data.code == -1){
                        that.isShow=true
                    }else{
                        that.isShow=false
                    }

                })
            })
        },
        //费用承担方选择
        chooseCostBear(){
            this.costBearShow = true
        },
        changecostBear(value) {
            this.caValidate.dataList=[
            {renterList:[{globaluser_id:'',amount:''}]},
            {supplierList:[{globaluser_id:'',amount:''}]},
            {companyList:[{amount:''}]},
            {ownerList:[{amount:''}]},
            {maintenanceFundList:[{amount:''}]},
          ]

            this.caValidate.costBearName = value.dictionaryTitle;
            this.caValidate.costBear = value.dictionaryValue
            this.costBearShow = false;

            this.$forceUpdate();

            console.log("caValidate.costBear:",this.caValidate.costBear)
        },
        //业主剩余金额
        ownerRemainingAmount(){
            let that = this
            let initData = {}
            initData.setHouse_id = that.setHouse_id
            ownerRemainingAmount(initData).then(function (response) {
                responseUtil.dealResponse(that, response, () => {
                    that.ownerRemaining.ownerRemainingAmount = response.data.data.balance
                })
            })
        },
        //最近应支日期
        ownerPayableDate(){
            let that = this
            let initData = {}
            initData.setHouse_id = this.setHouse_id
            ownerPayableDate(initData).then(function (response) {
                responseUtil.dealResponse(that, response, () => {
                    that.ownerRemaining.predictBillPayTime = response.data.data.predictBillPayTime
                    that.ownerRemaining.billStatus = response.data.data.billStatus
                })
            })
        },
        //添加供应商承担
        addSupplierBear(){
          this.caValidate.dataList[1].supplierList.push({globaluser_id:'',amount:''})
        },
        //打开供应商
        chooseSupplier(i){
          this.supIndex = i
          this.supShow = !this.supShow
        },
        //选择供应商
        selectSupplier(item){
          console.log(item)
          this.caValidate.dataList[1].supplierList[this.supIndex].userName = item.userName
          this.caValidate.dataList[1].supplierList[this.supIndex].globaluser_id = item.id
          this.supShow = false
        },
        //删除供应商
        cancelSupplier(i){
           if(i==0){
              this.caValidate.dataList[1].supplierList[i].userName = ''
              this.caValidate.dataList[1].supplierList[i].globaluser_id = ''
              this.caValidate.dataList[1].supplierList[i].amount = ''
              this.$forceUpdate();
              return
            }
          this.caValidate.dataList[1].supplierList.splice(i,1);  //删除index为i,位置的数组元素
          this.RenterBearIndex = 0
        },
        //新增租客
        addRenterBear(){
          this.caValidate.dataList[0].renterList.push({globaluser_id:'',amount:''})
        },
        //打开租客
        chooseRenterBear(i){
          this.RenterBearIndex = i
          this.renterBearShow = !this.renterBearShow
        },
        //选择租客
        selectRenterBear(item){
          console.log(item)
          this.caValidate.dataList[0].renterList[this.RenterBearIndex].userName = item.userName
          this.caValidate.dataList[0].renterList[this.RenterBearIndex].globaluser_id = item.id
          this.renterBearShow = false
        },
        //删除租客
        cancelRenterBear(i){
           if(i==0){
              this.caValidate.dataList[0].renterList[i].userName = ''
              this.caValidate.dataList[0].renterList[i].globaluser_id = ''
              this.caValidate.dataList[0].renterList[i].amount = ''
              this.$forceUpdate();
              return
            }
          this.caValidate.dataList[0].renterList.splice(i,1);  //删除index为i,位置的数组元素
          this.RenterBearIndex = 0
        }, 
        //租客列表
        getQueryContractors(){
            let that=this
            let initData={}
            initData.sethouse_id=that.setHouse_id
            initData.roomhouse_id=that.roomHouse_id
            queryContractors(initData).then(function (response) {
                responseUtil.dealResponse(that, response, () => {
                    console.log(response)
                    that.renterList=response.data.data.data
                })
            })
        },
        //供应商列表
        getQuerySupplierList(){
           let that=this
           let initData={}
           initData.user_id = globaluserId()
           initData.userRoleList = ['decorationWorker']
           queryUserRoleList(initData).then(function (response) {
               responseUtil.dealResponse(that, response, () => {
                   that.supplierList=response.data.data.decorationWorker
              })
           })
        },
        //租客列表
        getRenterList(val){
            this.renterList = []
            this.caValidate.dataList[0].renterList = [{globaluser_id:'',amount:''}]

            let that=this
            let initData={}
            initData.sethouse_id = that.setHouse_id
            initData.roomhouse_id = val == '-1' ? '' : Number(val)

            queryContractors(initData).then(function (response) {
                responseUtil.dealResponse(that, response, () => {
                    that.renterList=response.data.data.data
                })
            })
        },
        //房间选择
        chooseRoom(){
            this.roomShow = true
        },
        changeRoom(value) {
            this.caValidate.roomName = value.roomName;
            this.caValidate.roomid = value.roomid
            this.getRenterList(this.caValidate.roomid)
            this.roomShow = false;
        },
        //查找房间号
      selectHouse() {
          let that = this
          let initData = {}
          initData.user_id = globaluserId()
          initData.id = that.setHouse_id
          if (initData.id) {
              distributionChangeHouse(initData).then(function (response) {
                  responseUtil.dealResponse(that, response, () => {
                      that.roomList = response.data.data.roomList//房间
                      // that.caValidate.roomid=that.setHouse_id ? that.setHouse_id.toString() : '-1'

                        that.caValidate.roomName = ''
                        that.caValidate.roomid = ''
                        if(that.roomHouse_id != undefined){
                            that.roomList.filter(item =>{
                                if(that.roomHouse_id == item.roomid){
                                    that.caValidate.roomName = item.roomName;
                                    that.caValidate.roomid = item.roomid
                                }

                            })
                        }


                  })
              })
          }

      },
          //维修类型选择
        chooseRepairType(){
            this.repairTypeShow = true
        },
        changeRepairType(value) {
            this.caValidate.repairTypeName = value.dictionaryTitle;
            this.caValidate.repairType = value.id
            this.repairTypeShow = false;
            this.haveSameTypeDistribute(value.id)
        },
        getQueryrepairType(){
          let arr = []
          for(let i in this.repairTypeListCopy){
            if(this.repairTypeListCopy[i].dictionaryTitle.includes(this.repairName)){
              arr.push(this.repairTypeListCopy[i])
            }
          }
          this.repairTypeList = arr;
        },
        getQueryrepairPerson(){
          let arr = []
          for(let i in this.repairPersonListCopy){
            if(this.repairPersonListCopy[i].userName.includes(this.repairPersonName)){
              arr.push(this.repairPersonListCopy[i])
            }
          }
          this.repairPersonList = arr;
        }, 
        //维修人员选择
        chooseRepairPersonnel(){
            this.repairPerson = true
        },
        //选择维护人的方法
        changeStaffName(value) {
            this.caValidate.staffName = value.userName;
            this.caValidate.repairPersonnel = value.id
            this.repairPerson = false;
        },

        //完成时间
        clickFinishTimeShow() {
            this.isFinishTimeShow = !this.isFinishTimeShow;
            var panel = document.getElementById('finishTimePanel')
            if(panel){
                document.addEventListener('click',e =>{
                    if(!panel.contains(e.target)){
                        this.isFinishTimeShow = false
                    }
                })
            }
        },
        finishTimeConfirm(value){
            var date = new Date(value)
            this.caValidate.toTime = this.formatDate(date)
            this.isFinishTimeShow = false
        },
        // 格式化日期方法
        formatDate(dateTime) {
            console.log(dateTime.getMinutes())
            let year = `${dateTime.getFullYear()}`;
            let month  = `${dateTime.getMonth() + 1}` ;
            let date = `${dateTime.getDate()}` ;
            let hours = `${dateTime.getHours()}` ; ;
            let minutes = `${dateTime.getMinutes()}` ; ;
            if(month < 10){
                month = `0${dateTime.getMonth() + 1}`
            }
            if(date < 10){
                date = `0${dateTime.getDate()}`
            }
            if(hours < 10){
                hours = `0${dateTime.getHours()}`
            }
            if( minutes < 10){
                minutes = `0${dateTime.getMinutes()}`
            }
            return year+ '/' + month+ '/' + date + ' ' + hours + ':' + minutes;

        },
        finishTimeCancel(){
            this.isFinishTimeShow = false
        },

        }

    }
</script>

<style scoped lang="less">
    .repaireRemarksDiv{
        word-wrap: break-word;
    }
    .content {
        font-size: 14px;
    }

    .part {
        margin: 15px 15px 0 ;
    }

    .part-subtitle {
        color: #ff5d3b;
        font-size: 12px;
        font-weight: 900;
        position: relative;
        top: 8px;
    }

    .part-inputpart {
        display: flex;
        flex-direction: column;
        white-space: nowrap;
        overflow: hidden;
    }


    .part-inputpart-finishFee {
        display: flex;
        flex-direction: column;
        white-space: nowrap;
        overflow: hidden;
        border-radius: 8px;
          .part-inputpart-finishFee-row{
          margin-top: 0px!important;
          display: flex;
          flex-direction: row;
          align-items: center;
          height: 45px;
          background-color: #ffffff;
         } 
         .part-inputpart-finishFee-change{
          display: flex;
          flex-direction: row;
          align-items: center;
          height: 45px;
          background-color: #ffffff;
          margin-top: 15px;
         }
           .downMenu {
              width: 100%;
              height: 30px;
              display: flex;
              align-items: center;

              .downMenuValue {
                  width: 95%;
                  font-size: 14px;
                  color: #999;
              }
              .downMenuChoose {
                  width: 95%;
                  font-size: 14px;
                  color: #000;
              }

              .arrows {
                  width: 0;
                  height: 0;
                  border: 3.5px solid;
                  border-color: black transparent transparent transparent;
                  margin-top: 5px;
              }
          }
    }
    .part-inputpart-row {
        display: flex;
        flex-direction: row;
        border-radius: 8px;
        align-items: center;
        height: 45px;
        background-color: #ffffff;
        margin-top: 15px;
    }
    .part-inputpart-row2 {
      width: 100%;
      box-sizing: border-box;
      padding: 0.1rem 0.3rem 0rem;
      color: #7d7b7b;
      div{
        clear: both;
        line-height: 0.55rem;
        white-space:pre-wrap;
      }
      div:nth-of-type(2),div:nth-of-type(3),div:nth-of-type(4){
        text-indent: 1em;
      }
    }

    .part-inputpart-row-header {
        font-weight: 900;
        font-size: 15px;
        white-space: nowrap;
    }

    .otherExpenses-inputpart {
        display: flex;
        flex-direction: column;
        border-radius: 8px;
        overflow: hidden;
        margin-top: 10px;
    }

    .otherExpenses-inputpart-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: #ffffff;
        margin-top: 2px;
    }

    .part-inputpart-row input {
        border: 0;
        width: 100%;
    }

    .otherExpenses-address{
        padding: 15px 10px 15px 15px;
        font-size: 16px;
        font-weight: 900;
    }

    .otherExpenses-inputpart-row-bottom {
        margin-bottom: 5px;
        display: flex;
        align-items: baseline;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .otherExpenses-inputpart-row-left {
        display: inline-block;
        width: 47%;
        overflow: hidden;
        /*padding-right: 15px;*/
        margin-right: 5px;
    }

    .otherExpenses-inputpart-row-right {
        display: inline-block;
        width: 53%;
    }

    .otherExpenses-inputpart-column {
        display: flex;
        flex-direction: column;
        height: auto;
        padding: 16px 15px;
        justify-content: space-between;
        background-color: #ffffff;
        margin-top: 2px;
    }

    .part-inputpart-row-right {
        float: right;
        margin-right: 15px;
    }

    .part-inputpart-row-right-downArrow {
      width: 9px;
    }

    .part-inputpart-row-right-upArrow {
      width: 9px;
      transform: rotateX(180deg);
    }

    .part-inputpart-row-wrap {
        line-height: 24px;
    }

    .content-have {
        padding: 3px;
        margin: 0 10px 0 15px;
        border-radius: 50%;
        background-color: #ff5d3b;
    }

    .content-none {
        padding: 3px;
        margin: 0 10px 0 15px;
        border-radius: 50%;
        background-color: #c7c7c7;
    }

    .content-noRule {
        padding: 3px;
        margin: 0 10px 0 15px;
        // border-radius: 50%;
        // background-color: #c7c7c7;
    }

    .content-divide {
        font-size: 12px;
        color: #efefef;
        margin: 0 10px;
    }

    .otherExpenses-row-enablenon{
        margin-left: 15px;
    }

    .part-inputpart-row-graytext {
        color: #d8d8d8;
        width: 100%;
        font-size: 14px;
    }

    .part-inputpart-row-normaltext {
        color: black;
        //font-weight: 900;
        flex: auto;
    }

    .part-inputpart-row-redtext {
        width: 100%;
        color: #ff5d3b;
    }

    .part-inputpart-textarea {
        display: flex;
        flex-direction: column;
        margin-top: 15px;
        background-color: white;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }

    .part-inputpart-textarea div:first-child {
        margin-top: 0;
    }

    .part-inputpart-textarea textarea {
        height: 120px;
        background-color: #FaFaFa;
        border-radius: 8px;
        padding: 10px;
        margin: 0px 15px;
        border: none;
        resize: none;
    }

    .part-inputpart-textarea textarea::-webkit-input-placeholder {
        color: #d8d8d8;
    }
    .part-button1 {
        height: 45px;
        border-radius: 10px;
        background-color: #ededed;
        color: #ffffff;
        text-align: center;
        line-height: 45px;
        font-size: 15px;
        font-weight: 900;
        margin-top: 20px;
        margin-bottom: 10px;
        width: 100%;
        border: none;
    }

    .part-button {
        height: 45px;
        border-radius: 10px;
        background-color: #ededed;
        color: #ffffff;
        text-align: center;
        line-height: 45px;
        font-size: 15px;
        font-weight: 900;
        margin-top: 20px;
        margin-bottom: 90px;
        width: 100%;
        border: none;
    }

    .part-button-enabled {
        background-image: linear-gradient(to right, #ffc274, #ff5d3b);
    }

    .smallgraytext{
        color: #D8D8D8;
        font-size: 12px;
        margin-bottom: -3px;
    }

    .part-inputpart-row-right-timepick{
        width: 20px;
    }

    .part-inputpart-dropDownList{
        height: 23%;
        /*垂直方向滚动*/
        /*overflow-y: scroll;*/
        overflow: auto;
        width: 92.5%;
        position: absolute;
        z-index: 99;
    }

    .part-uploadpic-content{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        background-color: white;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        width: 100%;
        margin: 0 auto;
    }

    .part-uploadpic-module{
        width: 100%;
        margin: 20px 8px;
    }

    .part-uploadpic-module-pic{
        width: 80px;
        height: 80px;
        border-radius: 8px;
    }

    .part-uploadpic-module-supplier{
        width: 100%;
        margin: 0px 8px 20px;
    }

    .part-inputpart-cardpic-text{
        position: absolute;
        top: 72px;
        left: 33px;
        font-size: 12px;
        color: #d8d8d8;
    }

    .part-inputpart-cardpic-button{
        display: inline-block;
        background: url("../../../assets/images/grayAdd.png") center center no-repeat;
        width: 90px;
        height: 90px;
        border-radius: 8px;
        background-size: 30px;
        background-color: #fafafa;
        margin-left: 10px;
        margin-top: 10px;
    }

    .width{
        width: 100%;
    }

    .selectTimePopup{
        height: 6rem;
        width: 100%;
    }
    /*    申请详情图片DIV*/
    .applyDetailImgs{
        position: relative;
        height: 108px;
        width: 92%;
        margin: 15px auto ;
        background-color: #FFFFFF;
        border-radius: 8px;
    /*图片*/
    .applyDetailImg{
        width: 100px;
        height: 75px;
        border-radius: 8px;
        margin: 15px 0px 18px 4px ;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #e1eaec;
    img{
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
    }
    }
    /*    轮播的显示图片数*/
    .custom-indicator{
        position: absolute;
        left: 15px;
        top: 20px;
        padding: 1px 7px;
        font-size: 12px;
        background: rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        color: white;
    }
    }
    .vanLoading{
        position: fixed;
        z-index: 101;
        top: 50%;
        left: 47%;
    }
    .vanOverlay{
        z-index: 100;
    }

    .bottom_btn{
      position: fixed;
      border-top: 1px solid rgb(243, 243, 243);
      width: 100%;
      //height: 1.7rem;
      bottom: 0px;
      background-color: white;
      display: flex;
      justify-content: flex-end;
      padding: 10px;
      box-sizing: border-box;
      .btn {
        width: 120px;
        //margin-right: 10px;
        // margin-top: 10px;
        border-radius: 8px;
        background: linear-gradient(rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
        background: -webkit-linear-gradient(left,
        rgba(255, 194, 116, 1),
        rgba(255, 93, 59, 1));
        /* Safari 5.1 - 6.0 */
        background: -o-linear-gradient(right,
        rgba(255, 194, 116, 1),
        rgba(255, 93, 59, 1));
        /* Opera 11.1 - 12.0 */
        background: -moz-linear-gradient(right,
        rgba(255, 194, 116, 1),
        rgba(255, 93, 59, 1));
        /* Firefox 3.6 - 15 */
        background: linear-gradient(to right,
        rgba(255, 194, 116, 1),
        rgba(255, 93, 59, 1));
        color: rgb(255, 255, 255);
        font-size: 16px;
      }
    }
      /*弹窗样式*/
    .van-popup--bottom {
        background-color: #f8f8f8;
        //派单
        overflow: hidden;

        //取消订单
        .cancelOrdersImg {
            float: left;
            margin: 20px 5px 0 15px;
            width: 15px;
            height: 15px;
        }

        //取消订单
        .cnacelOrdersText {
            margin-top: 18px;
            font-size: 15px;
            font-weight: bold
        }

        //取消订单
        .confirmCancelOrdersText {
            margin-left: 35px;
            margin-bottom: 15px;
            font-size: 15px;
            font-weight: bold
        }
    }
     /*保存按钮不可点击样式*/
    .saveButton_Disable {
        /*background: linear-gradient(to right, rgba(184, 184, 184, 0.2), #B8B8B8 20%) repeat scroll 0% 0%;*/
        background-color: rgba(184, 184, 184, 0.2);
        width: 345px;
        height: 50px;
        border-radius: 8px;
        margin: 25px 50px 30px 15px;
        font-size: 18px;
        color: white;
        line-height: 1.22rem;
        text-align: center;
    }

    .saveButton_Enable {
        background: linear-gradient(to right, #fdc07f 0px, #f86513 100%) repeat scroll 0% 0%;
        color: white;
        width: 345px;
        height: 50px;
        border-radius: 8px;
        margin: 25px 50px 15px 15px;
        font-size: 18px;
        line-height: 1.22rem;
        text-align: center;
    }
    .part-inputpart-row-text {
        color: black;
        }
     input {
        font-size: 14px;
        margin-left: 15px;
        // background-color: #f4f4f4;
        border: 0;
        width: 250px;
    }

    input::-webkit-input-placeholder {
        color: #d9d9d9;
    }

    /*新增条目*/
    .newAdd{
        width: 25%;
        margin-left: 250px;
        margin-bottom: 20px;
        color: #f86513;
        font-size: 13px;
        text-align: right;
    }
    .part-subtitle2 {
        color: #ff5d3b;
        font-size: 12px;
        font-weight: 900;
        position: relative;
        // top: 8px;
    }
    /*    零件配置信息表*/
    .houseConfiguration {
        width: 92%;
        display: flex;
        margin: 5px auto 20px;
        /*取消按钮*/

        .cancelBtn {
            width: 22px;
            height: 20px;
            border-radius: 50%;
            background-color: #D7655A;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 13px;
            .cancelImg {
                height: 2px;
                width: 12px;
                background-color: white;
            }
        }
        /*    配置详情*/
        .houseDetail{
            width: 92%;
            background-color: white;
            margin-left: 5px;
            border-radius: 8px;
            /*配置名称*/
            .configuration{
                display: flex;
                flex-direction: row;
                align-items: center;
                height: 50px;

                .content-have {
                    padding: 3px;
                    margin: 0 5px 0 5px;
                    border-radius: 50%;
                    background-color: #ff5d3b;
                }
                .content-none {
                    padding: 3px;
                    margin: 0 5px 0 5px;
                    border-radius: 50%;
                    background-color: #c7c7c7;
                }
                /*名称的DIV              数量的DIV*/
                .configurationName,.configurationCount{
                    font-size: 15px;
                    font-weight: bold;
                    margin-right: 10px;
                    white-space: nowrap;
                }
                /*select菜单*/
                .configurationSelect{
                    background-color: white;
                    width: 75%;
                    border: none;
                    height: 35px;
                    font-size: 15px;
                    text-align: center;
                    line-height: 35px;
                }
                /*点击用的加号*/
                .changeCount{
                    width: 30px;
                    height: 30px;
                    text-align: center;
                    background-color: #eee;
                    color: #ddd;
                    line-height: 30px;
                    border-radius: 5px;
                }
                /*数量后面的占位DIV*/
                .placeholderDiv{
                    width:115px ;
                    input {
                        width: 90px;
                        height: 32.5px;
                        /*line-height: 40px;*/
                        font-size: 15px;
                        border: none;
                    }
                }
                /*数量值的DIV*/
                .count{
                    text-align: center;
                    width: 20px;
                    color: #D7655A;
                    font-size: 15px;
                    margin: 0 10px;
                    height: 30px;
                    line-height: 30px;
                    border: none;
                    background-color: white;
                }
            }
            /*    备注文本域*/
            .textareaRemarks{
                width: 82%;
                height: 50px;
                margin: 15px auto;
                border-radius: 8px;
                display: block;
                background-color: #f7f7f7;
                border: none;
                font-size: 13px;
                color: #999;
                padding: 15px 15px;
                resize: none;
            }
            /*房屋配置里的   请务必上传*/
            .upLoadText{
                font-size: 12px;
                color: #ff5d3b;
                text-align: right;
                width: 95%;
                margin-bottom: 10px;
            }
        }
    }
    .ruleOne{
        width: 100%;
        border: 0.1px solid #e6e6e6;
    }
    .downImage{
        width: 12px;
        height: 12px;
        margin-right: 10px;
    }
    .upImage{
        width: 12px;
        height: 12px;
        margin-right: 10px;
        transform: rotateX(180deg);
    }
    
</style>
