<template>
  <div class="contactPerson">
    <div>
      <van-overlay :show="mobileShow" z-index="100">
        <div class="wrapper" @click.stop>
          <div class="block">
            <!--拨打电话的div-->
            <div class="getPassword">
              {{dialogTitle}}
            </div>
            <!--联系人-->
            <div class="contactPersonText" >
              <div v-for="item in mobileList" class="signalMobile">
                <span>{{ item.userName }}：</span>
                <span><a style="color: #FF5D3B" :href="'tel:' + item.mobile">{{item.mobile.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2')}}</a></span>
              </div>

<!--              <p :class="userName==''? 'height':''">{{ item.userName }}</p>-->
<!--              <p class="password"><a style="color: #FF5D3B" :href="'tel:' + ownerMobile">{{item.mobile}}</a></p>-->
            </div>
          </div>
          <div class="closeImg" @click="closeMobileModel">
          </div>
        </div>
      </van-overlay>
    </div>
  </div>
</template>

<script>
import { Overlay,Button } from 'vant';
export default {
  name: "diaMobileList",
  components: {
    [Overlay  .name]:Overlay,
    [Button  .name]:Button
  },
  props: {
    ownerMobile: {
      type: String,
      default:''
    },
    dialogTitle: {
      type: String,
      default:''
    },
    mobileList:{
      type:Array,
      default:[]
    },
    userName: {
      type: String,
      default:''
    },
    mobileShow: {
      type: Boolean,
      default:false
    },
  },
  data() {
    return {
      show: false,
    }
  },
  methods:{
    closeMobileModel(){
      this.$emit('hideDialog');
    }
  }
}
</script>

<style lang="less" scoped >
@import "../../../assets/less/maskLayer.less";

.height{
  height: 5px;
}
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.block {
  width: 76%;
  //height: 22.5%;
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
}
/*    联系报修人的DIV*/
.getPassword{
  background: url("../../../assets/images/ContactPerson-img.png") no-repeat center center,
  linear-gradient(to right,#FFC274 ,#FF5D3B);
  text-align: center;
  height: 50px;
  line-height: 53px;
  color: white;
  background-size: 100%;
  font-size: 20px;
}
/*  报修人  */
.contactPersonText{
  box-sizing: border-box;
  padding: 10px;
  min-height: 140px;
  .signalMobile{
    margin: 20px auto;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    line-height: 14px;
  }
}
/*传入的密码值*/
.password{
  color: #ff8247;
  font-size: 20px;
  font-family: SimSun;
}

.telDiv{
  margin: 26px auto;
  width: 65%;
}
/*打电话的按钮*/
.btn{
  width: 100%;
  background-image: linear-gradient(to right,#FFC274,#FF5D3B);
  border: none;
  border-radius: 8px;
  color: white;
  font-size: 13px;
  height: 30px;
}
/*关闭按钮*/
.closeImg{
  margin-top: 38px;
  height: 30px;
  width: 30px;
  border: 1px solid white;
  border-radius: 50%;
  /*background: url("src/assets/images/GetDoorPassword-closeImg.png") no-repeat center center;*/
  background: url("../../../assets/images/GetDoorPassword-closeImg.png") no-repeat center center;
  background-size:18px 18px;
}
</style>